// /var/www/website2023/web-design.rampagesoft.com/my-app/src/page/Ecomm.js

import React from 'react';

function Ecomm() {
  return (
    <div>
      <h1>Online Store</h1>
      <p>Welcome to the E-commerce page!</p>
      {/* Add e-commerce content or components here */}
    </div>
  );
}

export default Ecomm;
