// MetaTAG.js
import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTAG = () => {
  return (
    <Helmet>

      <title>รับออกแบบเว็บไซต์ พัฒนาเว็บไซต์ รับทำเว็บไซต์ Website Design RAMPAGESOFT</title>

      <link rel="icon" type="image/png" href="https://afaa.website/s/ff6970.png" />

      <meta http-equiv="revisit-after" content="3 days" />
      <meta http-equiv="Cache-control" content="public" />
      <meta http-equiv="imagetoolbar" content="yes" />

      <meta name="copyright" content="AFRA APACHE" />
      <meta name="owner" content="AFRA APACHE" />
      <meta name="web_author" content="AFRA APACHE" />
      <meta name="author" content="AFRA APACHE" />

      <meta name="description" content="รับออกแบบเว็บไซต์ ตามสไตล์แบรนด์ และเป้าหมายทางธุรกิจ พัฒนาเว็บไซต์ที่รองรับทุกอุปกรณ์ ทั้งคอมพิวเตอร์ แท็บเล็ต และสมาร์ทโฟน พัฒนาระบบจัดการเนื้อหาเว็บไซต์ CMS เพื่อให้อัปเดตและดูแลเว็บไซต์ได้ง่าย รับออกแบบเว็บไซต์ พัฒนาเว็บไซต์ รับทำเว็บไซต์ Website Design RAMPAGESOFT" />


      <meta name="keywords" content="รับออกแบบเว็บไซต์ รับทำเว็บไซต์ ออกแบบเว็บ เว็บดีไซน์ พัฒนาระบบ ทำเว็บที่ไหนดี เว็บแอพพลิเคชั่น ระบบจองออนไลน์ ร้านค้าออนไลน์ เว็บไซต์รถเช่า เว็บไซต์พระเครื่อง เว็บไซต์ท่องเที่ยว เว็บไซต์โรงแรม เว็บไซต์เครือข่าย MLM เว็บไซต์โรงงาน อุตสาหกรรม เว็บไซต์ราชกาล เว็บไซต์หน่วยงาน เว็บไซต์เทศบาล เว็บไซต์ตำบล เว็บไซต์จังหวัด เว็บไซต์สำหรับร้านอาหาร เว็บไซต์สำหรับโรงเรียน  เว็บไซต์สำหรับบล็อกเกอร์ เว็บไซต์สำหรับศิลปิน และนักออกแบบ เว็บไซต์สำหรับธุรกิจในกรุงเทพ เว็บไซต์สำหรับธุรกิจในเชียงใหม่  เว็บไซต์สำหรับธุรกิจในภูเก็ต  เว็บไซต์สำหรับธุรกิจในไทย  เว็บไซต์คุณภาพสูง  เว็บไซต์ที่ปรับแต่งได้  บริการออกแบบเว็บไซต์มืออาชีพ  บริษัทรับพัฒนาเว็บไซต์ที่เชื่อถือได้ RAMPAGESOFT" />

      <meta name="news_keywords" content="รับออกแบบเว็บไซต์ พัฒนาเว็บไซต์ รับทำเว็บไซต์ เว็บดีไซน์ พัฒนาระบบ เขียนโปรแกรม เว็บแอพพลิเคชั่น website Development Website Design Web Applications RAMPAGESOFT" />


      <meta property="og:title" content="รับออกแบบเว็บไซต์ พัฒนาเว็บไซต์ รับทำเว็บไซต์ Website Design RAMPAGESOFT" />
      <meta property="og:description" content="รับออกแบบเว็บไซต์ ตามสไตล์แบรนด์ และเป้าหมายทางธุรกิจ พัฒนาเว็บไซต์ที่รองรับทุกอุปกรณ์ ทั้งคอมพิวเตอร์ แท็บเล็ต และสมาร์ทโฟน พัฒนาระบบจัดการเนื้อหาเว็บไซต์ CMS เพื่อให้อัปเดตและดูแลเว็บไซต์ได้ง่าย รับออกแบบเว็บไซต์ พัฒนาเว็บไซต์ รับทำเว็บไซต์ Website Design RAMPAGESOFT" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.rampagesoft.com/webdesign/" />
      <meta property="og:image" content="https://afaa.website/s/ff6970.png" />

      <meta property="og:site_name" content="web-design.rampagesoft.com" />
      <meta property="og:locale" content="th_TH" />
      <meta property="og:image:secure_url" content="https://afaa.website/s/ff6970.png" />	

      <meta name="generator" content="react" />
      <meta name="distribution" content="Global" />
      <meta name="rating" content="General" />
      <meta name="expires" content="Never" />

      <link rel="canonical" href="https://www.rampagesoft.com/webdesign/" />
      <link rel="alternate" hreflang="en" href="https://www.rampagesoft.com/webdesign/>" />
      <link rel="alternate" hreflang="th" href="https://www.rampagesoft.com/webdesign/>" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@AFRAAPACHE" />
      <meta name="twitter:domain" content="https://www.rampagesoft.com/webdesign/" />
      <meta name="twitter:title" content="รับออกแบบเว็บไซต์ พัฒนาเว็บไซต์ รับทำเว็บไซต์ Website Design RAMPAGESOFT" />
      <meta name="twitter:description" content="รับออกแบบเว็บไซต์ ตามสไตล์แบรนด์ และเป้าหมายทางธุรกิจ พัฒนาเว็บไซต์ที่รองรับทุกอุปกรณ์ ทั้งคอมพิวเตอร์ แท็บเล็ต และสมาร์ทโฟน พัฒนาระบบจัดการเนื้อหาเว็บไซต์ CMS เพื่อให้อัปเดตและดูแลเว็บไซต์ได้ง่าย รับออกแบบเว็บไซต์ พัฒนาเว็บไซต์ รับทำเว็บไซต์ Website Design RAMPAGESOFT" />
      <meta name="twitter:image" content="https://afaa.website/s/ff6970.png" />

      <meta name="apple-mobile-web-app-title" content="รับออกแบบเว็บไซต์ พัฒนาเว็บไซต์ รับทำเว็บไซต์ Website Design RAMPAGESOFT" />
      <meta name='apple-mobile-web-app-capable' content='yes' />
      <meta name='apple-touch-fullscreen' content='yes' />
      <meta name='apple-mobile-web-app-status-bar-style' content='black' />
      <link href='https://afaa.website/s/ff6970.png' rel='apple-touch-icon' type='image/png' />
      <link href='https://afaa.website/s/ff6970.png' rel='apple-touch-startup-image' />

      <meta name='mssmarttagspreventparsing' content='true'/>
      <meta content="IE=edge,chrome=1" http-equiv="X-UA-Compatible"/>
      <meta name='msapplication-starturl' content='https://www.rampagesoft.com/webdesign/'/>
      <meta name='msapplication-window' content='width=800;height=600'/>
      <meta name='msapplication-navbutton-color' content='black'/>
      <meta name='application-name' content='Web Design RAMPAGESOFT'/>
      <meta name='msapplication-tooltip' content='Web Design RAMPAGESOFT'></meta>




    </Helmet>
  );
};

export default MetaTAG;
