// /var/www/website2023/web-design.rampagesoft.com/my-app/src/detailphoto.js

import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import crmData from '../data/detail-photo-for-crm.json'; // Import JSON data for CRM
import designData from '../data/detail-photo-for-design.json'; // Import JSON data for Design

const FadeInScaleSection = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <div ref={ref} style={{
      opacity: inView ? 1 : 0,
      transform: inView ? 'scale(1)' : 'scale(0.95)',
      transition: 'all 1.5s ease-out'
    }}>
      {children}
    </div>
  );
};

const Detailphoto = ({ pageType }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    // Dynamically load the correct JSON data based on the pageType prop
    if (pageType === 'crm') {
      setData(crmData);
    } else {
      setData(designData);
    }
  }, [pageType]);

  return (
    <section className="about-us-3 overflow-hidden section-space-top bg-subk">
      <div className="container pad-tb-50">
        <div className="row flex-column-reverse flex-xl-row">
          <div className="col-xl-6 df-cen pad-20">
            <div className="about-us-3__content">
              <FadeInScaleSection>
                <span className="section__subtitle section__subtitle-2 uppercase justify-garantee mb-20 mb-xs-10">
                  <img className="xlab-img rightLeft" src={data.logo} alt="Rampagesoft Logo" />
                  {data.subtitle}
                </span>
                <h2 className="head1-detallphoto">{data.headline1}</h2>
                <h2 className="head2-detallphoto">{data.headline2}</h2>
                <p className="text-detail-p">{data.description}</p>
              </FadeInScaleSection>

              <a href="https://line.me/R/ti/p/%40ave2890k" className="rs-btn button-deatil-p fade-in" style={{ animationDelay: ".6s" }}>ติดต่อเรา</a>
            </div>
          </div>
          <div className="col-xl-6 pad-20">
            <div className="about-us-3__media mt-lg-40 mt-md-35 mt-sm-35 mt-xs-35">
              <Swiper
                modules={[Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                scrollbar={{ draggable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
              >
                {data.slides && data.slides.map((slide, index) => (
                  <SwiperSlide key={index}>
                    <img className="fade-in" style={{ animationDelay: `${0.7 + index * 0.1}s` }} src={slide.src} alt={slide.alt} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Detailphoto;
