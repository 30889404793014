import React from "react";
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';



const FadeInSection = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,  // 50% of the element needs to be visible
  });

  return (
    <div ref={ref} style={{ opacity: inView ? 1 : 0, transition: 'opacity 0.5s ease-in' }}>
      {children}
    </div>
  );
};


const Bannergarantee = () => {
  return (
    <section className="bg-garantee">
      <div className="container pad-tb-50">
        <div className="about-us-3__content">
        <FadeInSection>
          <h2 className="head1-garantee garantee-res">
            ครบวงจรบริการรับทำเว็บไซต์ ออกแบบเว็บไซต์ พัฒนาเว็บไซต์ SEO
            ดิจิทัลมาร์เก็ตติ้ง
          </h2>
          <h2 className="head2-garantee">ประสบการณ์กว่า <CountUp start={0} end={8} duration={7.75} /> ปี</h2>

          <div className="row pad-tb-60 ">
            <div className="col-xl-4 center-col garantee-res">
              <h2 className="p-garantee">มากกว่า <CountUp start={0} end={300} duration={5.75} />+ ผลงาน</h2>
              <a
                href="https://dev.rampagesoft.com/"
                class="center-icon"
              >
                <i class="fa-solid fa-circle-arrow-right icon-button-garantee"></i>
              </a>
            </div>
            <div className="col-xl-4 center-col garantee-res">
              <h2 className="p-garantee">ภาษาเขียนโปรแกรมที่รองรับ <CountUp start={0} end={11} duration={6.75} />+ </h2>
              <a
                href="https://dev.rampagesoft.com/"
                class="center-icon"
              >
                <i class="fa-solid fa-circle-arrow-right icon-button-garantee"></i>
              </a>
            </div>
            <div className="col-xl-4 center-col garantee-res">
              <h2 className="p-garantee">
                เซิร์ฟเวอร์คุณภาพสูง<br />รองรับเข้าถึงผู้ใช้ทั่วโลก
              </h2>
            </div>
          </div>

          <h2 className="head2-garantee">
            เว็บไซต์ลื่นไหล ปลอดภัย ไร้ปัญหา ทีมสนับสนุนพร้อมดูแล เซิร์ฟเวอร์แรง
            ตอบสนองรวดเร็ว SEO พุ่ง ดันเว็บไซต์ของคุณสู่หน้าแรก Google
            รองรับทุกความต้องการ
          </h2>

          </FadeInSection>
        </div>
      </div>
    </section>
  );
};

export default Bannergarantee;
