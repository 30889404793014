// /var/www/website2023/web-design.rampagesoft.com/my-app/src/faq.js
import React from 'react';
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";

const FAQ = ({ content }) => {
  return (
    <section className="pad-tb-100 bg-garantee">
      <div className="container">
        <h2 className="head-fqa">Q&A • คำตอบสำหรับคำถามที่พบบ่อย</h2>
        <MDBContainer className="mt-5 fqa-text" style={{ maxWidth: "1000px" }}>
          <MDBAccordion alwaysOpen initialActive={1}>
            {content.map((item) => (
              <MDBAccordionItem
                key={item.id}
                collapseId={item.id}
                headerTitle={item.title}
              >
                {item.description}
              </MDBAccordionItem>
            ))}
          </MDBAccordion>
        </MDBContainer>
      </div>
    </section>
  );
};

export default FAQ;
