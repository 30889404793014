///var/www/website2023/web-design.rampagesoft.com/my-app/src/banner.js

import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLocation } from 'react-router-dom'; // Import this at the top

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';


import bannerPc1 from './media/banner/banner-rampagesoft1.webp';
import bannerTb1 from './media/banner/ram_tb1.webp';
import bannerMo1 from './media/banner/ram_mo1.webp';

import bannerPc2 from './media/banner/banner-rampagesoft2.webp';
import bannerTb2 from './media/banner/ram_tb2.webp';
import bannerMo2 from './media/banner/ram_mo2.webp';


// Assuming you have an array of objects for each banner with paths for pc, tablet, and mobile
const bannerImages = [
  {
    id: 'banner1',
    pc: bannerPc1,
    tablet: bannerTb1,
    mobile: bannerMo1,
  },
  {
    id: 'banner2',
    pc: bannerPc2,
    tablet: bannerTb2,
    mobile: bannerMo2,
  },
  // Add more banner objects as needed
];

const Banner = () => {
  const [deviceType, setDeviceType] = useState('pc');
  const location = useLocation(); // To check the current route

  // New banner for CRM page
  const crmBanner = {
    id: 'crmBanner',
    pc: 'https://afaa.website/s/a0f923.jpg', // URL for CRM banner (PC)
    tablet: 'https://afaa.website/s/a0f923.jpg', // URL for CRM banner (Tablet)
    mobile: 'https://afaa.website/s/dafcbd.jpg', // URL for CRM banner (Mobile)
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setDeviceType('mobile');
      } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
        setDeviceType('tablet');
      } else {
        setDeviceType('pc');
      }
    }

    // Set initial device type
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  // Insert crmBanner as the first item when on the CRM page
  const currentBannerImages = location.pathname === '/crm' 
    ? [crmBanner, ...bannerImages] // Add the CRM banner only on '/crm'
    : bannerImages;


  return (
    <div className="banner-3 background-image space overflow-hidden">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
       {currentBannerImages.map((banner) => (
          <SwiperSlide key={banner.id}>
            <img src={banner[deviceType]} alt={`Banner for ${deviceType}`} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Banner;
