/// /var/www/website2023/web-design.rampagesoft.com/my-app/src/component/spahotel.js

import { useInView } from 'react-intersection-observer';
import '../css/spahotel.scss';


const FadeInSection = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <div ref={ref} style={{ opacity: inView ? 1 : 0, transition: 'opacity 1.0s ease-in' }}>
      {children}
    </div>
  );
};

const SpaHotel = ({ content }) => {
  return (
    <section className="about-us-3 overflow-hidden bg-garantee">
      <div className="row flex-xl-row">
        <div className="col-xl-6 df-cen pad-tb-100 pad-lr-30 bg-garantee">
          <FadeInSection>
            <div className="about-us-3__content">
              <h2 className="head1-spa pad-tb-30">{content.heading1}</h2>
            
              <div className="description mb-40 mb-sm-25 mb-xs-20">
                <p className="text-spa-p textcenter">
                  {content.description1.split('\n').map((line, index) => (
                    <span key={index}>
                      {line}
                      <br />
                    </span>
                  ))}

                </p>
              </div>
            </div>
          </FadeInSection>
        </div>
        <div className="col-xl-6 df-cen pad-tb-100 pad-lr-100 bg-bklo">
          <div className="about-us-3__content">
            <FadeInSection>
            <h2 className="head2-spa pad-tb-30 text-head-1">{content.heading2}</h2>
              <div className="description mb-40 mb-sm-25 mb-xs-20">
                <p className="text-spa-rp textcenter">
                  {content.description2.split('\n').map((line, index) => (
                    <span key={index}>
                      {line}
                      <br />
                    </span>
                  ))}
                </p>
              </div>
            </FadeInSection>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SpaHotel;
