/// /var/www/website2023/web-design.rampagesoft.com/my-app/src/component/text5icon.js

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Correct use of useLocation
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from 'swiper/modules';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import 'swiper/css/autoplay';

//import servicesData from "../data/icontextData";



const MySwiper = ({ data,headingText }) => {
  const location = useLocation(); // Assign location using useLocation hook
  const [images, setImages] = useState({});
  const [openModalId, setOpenModalId] = useState(null);
  const lineUrl = "https://lin.ee/lLTd0gY";
  const whatsappUrl = "https://wa.me/message/WFPUI4FAAKGCK1";

  
  useEffect(() => {
    if (location.pathname === "/crm") {
      import('../css/crm.scss');
    } else if (location.pathname === "/") {
      import('../css/iwantto.scss');
    }
  }, [location.pathname]); // Correctly reference location.pathname without extra dependencies



  useEffect(() => {   

    const loadImages = async () => {
      const imagePromises = data.map(async (service) => {
        const isImgUrl = service.img.startsWith('https');
        const isPopupUrl = service['img-popup'].startsWith('https');

        const swiperUrl = isImgUrl
          ? service.img
          : await import(`../media/slide/${service.img}`).then(module => module.default);

        const popupUrl = isPopupUrl
          ? service['img-popup']
          : await import(`../media/popup/${service['img-popup']}`).then(module => module.default);

        return { id: service.id, swiperUrl, popupUrl };
      });

      const imageResults = await Promise.all(imagePromises);

      const newImages = imageResults.reduce((acc, { id, swiperUrl, popupUrl }) => {
        acc[id] = { swiperUrl, popupUrl };
        return acc;
      }, {});

      setImages(newImages);
    };

    loadImages();
  }, [data]); // Use 'data' as a dependency

  const handleOpenModal = (id) => {
    setOpenModalId(id);
  };

  const handleCloseModal = () => {
    setOpenModalId(null);
  };


  return (
    <div className="bg-iwantto pad-tb-60">
      <div className="container">
      <h3 className="headtopicon5 pad-b-30">{headingText}</h3>
        <Swiper
          modules={[Autoplay, Pagination]}
          spaceBetween={50}
          slidesPerView={4}
          loop={true}
          navigation={true}
          pagination={false}
          autoplay={{ delay: 30000, disableOnInteraction: false }}
          speed={800}
          effect="fade"
          breakpoints={{
            400: {
              slidesPerView: 2
            },
            350: {
              slidesPerView: 1
            },
            786: {
              slidesPerView: 2
            },
            992: {
              slidesPerView: 3
            },
            1200: {
              slidesPerView: 4
            }
          }}
        >
          {data.map((service) => (
            <SwiperSlide key={service.id}>
             <div className="overlay"></div>
              <div
                className="swiper-content icon5-hw swiper-image-bg"
                style={{ backgroundImage: `url(${images[service.id]?.swiperUrl})` }}
              >
                <div className="slide-info icon5-head">
                  <Button className="headicon5 text-white" variant="primary" onClick={() => handleOpenModal(service.id)}>
                    {service.title}
                  </Button>
                </div>

                <Button className="icon5-text button-arrow-icon5" variant="primary" onClick={() => handleOpenModal(service.id)}>
                  <i className="fa-solid fa-circle-arrow-right icon-button"></i>{" "}
                </Button>

              </div>

              {openModalId === service.id && (
                <Modal
                  show={openModalId === service.id}
                  onHide={handleCloseModal}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{service.title}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <img src={images[service.id]?.popupUrl} alt={`${service.title} - รับทำเว็บไซต์`} />
                  </Modal.Body>
                  <Modal.Footer>

                    <div className='whatsapp-button'>
                      <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
                        <Button variant="whatsapp"><i class="fa-brands fa-whatsapp"></i> Whatsapp</Button>
                      </a>
                    </div>
                    <div className='line-button'>
                      <a href={lineUrl} target="_blank" rel="noopener noreferrer">
                        <Button variant="addline"><i class="fa-brands fa-line"></i> Line</Button>
                      </a>
                    </div>

                    <Button variant="secondary" onClick={handleCloseModal}>
                      Close
                    </Button>

                  </Modal.Footer>
                </Modal>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default MySwiper;
