// /var/www/website2023/web-design.rampagesoft.com/my-app/src/page/CRM.js

import React from 'react';
import Banner from '../banner';
import Text5icon from '../component/text5icon';
import Detailphoto from '../component/detailphoto';
import Detailphoto2 from '../component/detailphoto2';
import Spahotel from '../component/spahotel';
import Bgbanner from '../component/bg-banner';
import Slidebar from '../component/slidebar';
import Packagewebsite from '../component/package-website';
import FAQ from '../component/faq';
import TTTRAM from '../tttrampage';

import appServicesData from "../data/appServicesData.json"; 
import featuresStoriesData from "../data/featuresStories.json";
import crmBgBannerContent from "../data/crmBgBannerContent.json";
import crmDetailPhotoContent from "../data/crmDetailPhotoContent.json";
import crmSpaHotelContent from "../data/crmSpaHotelContent.json";
import crmPackageContent from "../data/crmPackageContent.json";
import crmFaqData from "../data/crmFaqData.json";

// Dynamically load images by prepending the correct path
const updatedCrmPackageContent = {
  ...crmPackageContent,
  packages: crmPackageContent.packages.map(pkg => ({
    ...pkg,
    image: require(`../media/${pkg.image}`)  // Prepend media path
  }))
};

function CRM() {
  return (
    <>
      <Banner />
      <Text5icon data={appServicesData.services || []} headingText={appServicesData.headingText} />
      <Detailphoto pageType="crm" /> 
      <Bgbanner heading={crmBgBannerContent.heading} />
      <Detailphoto2 content={crmDetailPhotoContent} />
      <Spahotel content={crmSpaHotelContent} />
      <Slidebar data={featuresStoriesData.services || []} heading={featuresStoriesData.headingText} />
      <Packagewebsite content={updatedCrmPackageContent} /> 
      <FAQ content={crmFaqData} />
      <TTTRAM />
    </>
  );
}

export default CRM;
