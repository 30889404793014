// /var/www/website2023/web-design.rampagesoft.com/my-app/src/component/detailphoto2.js
// Import React and Swiper dependencies as before
import React from 'react';
import { Scrollbar, A11y } from 'swiper/modules';
import { useInView } from 'react-intersection-observer';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';

const ZoomInBlur = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <div ref={ref} style={{
      opacity: inView ? 1 : 0,
      transform: inView ? 'scale(1)' : 'scale(1.1)',
      filter: inView ? 'blur(0px)' : 'blur(4px)',
      transition: 'all 1.5s ease-out'
    }}>
      {children}
    </div>
  );
};

const DetailPhoto2 = ({ content }) => {
  return (
    <section className="about-us-3 overflow-hidden section-space-top bg-subk">
      <div className="container pad-tb-50">
        <div className="row flex-column-reverse flex-xl-row">
          <div className="col-xl-6 pad-20">
            <div className="about-us-3__media mt-lg-40 mt-md-35 mt-sm-35 mt-xs-35">
              <Swiper
                modules={[Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                scrollbar={{ draggable: true }}
              >
                {content.slides && content.slides.map((slide, index) => (
                  <SwiperSlide key={index}><img src={slide} alt={content.heading1} /></SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>

          <div className="col-xl-6 df-cen pad-20">
            <div className="about-us-3__content">
              <ZoomInBlur>
                <span className="section__subtitle section__subtitle-2 uppercase justify-garantee mb-20 mb-xs-10">
                  <img className="xlab-img rightLeft" src={content.logo} alt="Rampagesoft Logo" />
                  {content.subtitle}
                </span>

                <h2 className="head1-detallphoto">
                  {content.heading1}
                </h2>
                <h2 className="head2-detallphoto">
                  {content.heading2}
                </h2>

                <div className="description mb-40 mb-sm-25 mb-xs-20">
                  <p className='text-detail-p'>
                    {content.description}
                  </p>
                </div>
              </ZoomInBlur>
              <a href="https://line.me/R/ti/p/%40ave2890k" class="rs-btn button-deatil-p">
                {content.contactButtonText}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DetailPhoto2;
