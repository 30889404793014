///var/www/website2023/web-design.rampagesoft.com/my-app/src/component/slidebar.js

import React, { useState, useEffect } from 'react';
import { Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';

const Sidebar = ({ data, heading }) => {  // Add 'heading' as a prop
  const [images, setImages] = useState({});
  const [slidesPerView, setSlidesPerView] = useState(3); // Default setting

  useEffect(() => {
    const loadImages = async () => {
      const imagePromises = data.map(async (service) => {
        if (service.img.startsWith('https')) {
          return { id: service.id, imgUrl: service.img };  // Use the URL directly
        } else {
          const imgModule = await import(`../media/success/${service.img}`);
          return { id: service.id, imgUrl: imgModule.default };  // Load local image
        }
      });

      const imageResults = await Promise.all(imagePromises);
      const newImages = imageResults.reduce((acc, { id, imgUrl }) => {
        acc[id] = imgUrl;
        return acc;
      }, {});

      setImages(newImages);
    };

    loadImages();

    const updateSlidesPerView = () => {
      if (window.innerWidth < 768) {
        setSlidesPerView(1);
      } else if (window.innerWidth < 1024) {
        setSlidesPerView(2);
      } else {
        setSlidesPerView(3);
      }
    };

    window.addEventListener("resize", updateSlidesPerView);
    updateSlidesPerView();

    return () => window.removeEventListener("resize", updateSlidesPerView);
  }, [data]);



  return (
    <section className="pad-tb-150 bg-success">
      <div className="container box-border-su">
      <h3 className="headsuccess pad-b-30">{heading}</h3> 
        <Swiper
          modules={[Scrollbar, A11y]}  // ใช้เพียง Scrollbar และ A11y
          spaceBetween={50}
          slidesPerView={slidesPerView}
          loop={true}
          scrollbar={{ draggable: true }}  // เอาเฉพาะ scrollbar ที่สามารถลากได้
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >
          {data.map((service) => (
            <SwiperSlide key={service.id}>
              <div className="">
                <div>
                  <a
                    href={service.link} // Assuming you might want to use dynamic links
                    className=""
                  >
                    <img className="img-su-pad" src={images[service.id]} alt={service.title} />

                  </a>
                </div>
                <div className="box-su-pad">
                  <div className="latest-gallery__item-content-left">
                    <a href={service.link}>
                      <h4 className="head-box-text">{service.title}</h4>
                    </a>
                    <p className="head-box-p">{service.description}</p>
                  </div>
                  <a href={service.link} className="rs-btn button-deatil-p">
                    {service.namelink}
                  </a>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Sidebar;
