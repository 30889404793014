///var/www/website2023/web-design.rampagesoft.com/my-app/src/menu.js

import React, { useState } from "react";
import {
  Navbar,
  Nav,
  Container,
  Offcanvas,
  NavDropdown,
} from "react-bootstrap";
import menuData from "./data/menuData.json";
import logoram from "./media/logo/LOGO_RAMPAGESOFT_2023.png";

import "./css/rampagesoft.css";
import "./css/menu.scss"; // Import the newly created menu.scss

function ResponsiveNavbar() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      { }
      <div
        className="header-top d-none d-md-block"
        style={{ backgroundColor: "#9dccf1" }}
      >
        <div className="container">
          <div className="top-header__menu-wrapper d-flex justify-content-between align-items-center">
            <ul className="header-top-menu d-flex">

              <li>
                <a className="link-menu-so" href="https://wa.me/message/WFPUI4FAAKGCK1">
                WHATSAPP
                </a>
              </li>
              <li>
                <a className="link-menu-so" href="https://lin.ee/lLTd0gY">
                  LINE
                </a>
              </li>
              <li>
                <a className="link-menu-so" href="mailto:info@rampagesoft.com">
                  E-MAIL
                </a>
              </li>


            </ul>

            <ul className="header-top-socail-menu d-flex">

              <a href="https://afraapache.com/" className="text-company-af">AFRA APACHE</a>

              <li>
                <a href="https://x.com/AFRAAPACHE">
                  <i className="fa-brands fa-x-twitter"></i>
                </a>
              </li>
              <li>
                <a href="https://www.reddit.com/user/seo_siamwebsite/">
                  <i className="fa-brands fa-reddit-alien"></i>
                </a>
              </li>
              <li>
                <a href="https://github.com/siamwebsite">
                  <i class="fa-brands fa-github"></i>
                </a>
              </li>

              <li>
                <a href="https://www.facebook.com/rampagesoft">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>


              <li>
                <a href="https://www.youtube.com/channel/UCy25ys9ds9KaIVWcR9TnbPw">
                  <i className="fa-brands fa-youtube"></i>
                </a>
              </li>

            </ul>
          </div>
        </div>
      </div>

      {/* Navbar */}
      <div className="mega__menu-wrapper " >
        <div className="header__main">
          <Navbar
            bg="light"
            expand="lg"
            fixed="top"
            className="bg-menu-rampage wd100 p-relative"
          >
            <Container className="header__main">
              <div className="header__left">
                <Navbar.Brand href="/">
                  <img src={logoram} alt="Logo" />
                </Navbar.Brand>
              </div>

              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                onClick={handleShow}
              />
              <Navbar.Offcanvas
                id="basic-navbar-nav"
                placement="end"
                show={show}
                onHide={handleClose}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title><img className="logomobile" src={logoram} alt="RAMPAGESOFT LOGO" /></Offcanvas.Title>
                </Offcanvas.Header>
                
                <Offcanvas.Body>
                  <div class="header__middle">
                    <Nav className="ms-auto">
                      {menuData.mainMenu.map((item, index) =>
                        item.submenu ? (
                          <NavDropdown
                            title={item.text}
                            id={`nav-dropdown-${index}`}
                          >
                            {item.submenu.map((subItem, subIndex) => (
                              <NavDropdown.Item
                                href={subItem.href}
                                key={subIndex}
                              >
                                {subItem.text}
                              </NavDropdown.Item>
                            ))}
                          </NavDropdown>
                        ) : (
                          <Nav.Link href={item.href} key={index}>
                            {item.text}
                          </Nav.Link>
                        )
                      )}
                    </Nav>
                  </div>

                  <div class="header__btn d-flex align-items-center justify-content-center dis-none-mo mar-15">
                    <span class="icon">
                      <i class="fa-solid fa-phone"></i>
                    </span>
                    <div class="text">
                      <span className="text-bk">ติดต่อสอบถาม</span>
                      <a href="tel:0956605550">
                        <h5>(095) 660-5550</h5>
                      </a>
                    </div>
                  </div>

                  <div class="header__btn d-flex align-items-center justify-content-center dis-none-mo mar-15">
                    <span class="icon">
                      <i class="fa-brands fa-line"></i>
                    </span>
                    <div class="text">
                      <span>Add Line</span>
                      <a href="https://lin.ee/lLTd0gY">
                        <h5>@siamwebsite</h5>
                      </a>
                    </div>
                  </div>
                </Offcanvas.Body>
              </Navbar.Offcanvas>

              <div class="header__right dis-res-nav">
                <div class="header__action d-flex align-items-center">
                  <div class="header__btn-wrap d-none d-sm-inline-flex">
                    <div class="header__btn d-flex align-items-center justify-content-center">
                      <span class="icon">
                        <i class="fa-solid fa-phone"></i>
                      </span>
                      <div class="text">
                        <span>ติดต่อสอบถาม</span>
                        <a href="tel:0956605550">
                          <h5>(095) 660-5550</h5>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </Container>
          </Navbar>
        </div>
      </div>
    </>
  );
}

export default ResponsiveNavbar;
