import React from 'react';
import footerData from './data/footerData.json'; // Adjust path if needed


const Footer = () => {
  // Function to render links
  const renderLinks = (links) => links.map((link) => (
    <li key={link.name}><a href={link.url}>{link.name}</a></li>
  ));

  // Function to render contact info
  const renderContacts = (contacts) => contacts.map((contact) => (
    <li key={contact.type}>
      <a href={contact.url}>
        <i className={contact.icon}></i> <span className="text">: {contact.text}</span>
      </a>
    </li>
  ));

  return (
    <footer>
      <section className="footer__area-common footer__area-common-2 theme-bg-2" data-background="assets/imgs/footer/footer-bg-2.svg">
        <div className="container">
          <div className="footer__main">
            <div className="row">
              <div className="col-xl-3 footer__pad">
                <div className="footer__content">
                  <div class="footer__widget-title"><h4>AFRA APACHE</h4></div>
                  <div className="row footer__address">
                    <div className="col-xl-6 col-6 col-lg-6">
                      <p>CHALOEM PHRAKIAT RAMA 9 SOI 61 SUBDISTRICT,
                        PRAWET DISTRICT, BANGKOK 10250
                      </p>
                    </div>
                    <div className="col-xl-6 col-6 col-lg-6">
                      <p>3/643 ,KLANG MUANG ROAD, MUANG GAO SUB-DISTRICT , KHONKAEN PROVINCE, 40000
                      </p>
                    </div>
                  </div>
                  <p>RAMPAGESOFT บริการออกแบบเว็บไซต์ พัฒนาระบบ บริการกลยุทธ์ SEO ตอบโจรย์ทุกธุรกิจ สามารถออกแบบได้ตามต้องการ เหมาะกับธุรกิจ ที่กำลังจะขยายกิจการ SME ขนาดกลาง
                  </p>
                </div>

                <div class="footer__link dis-footer">
                  <ul>
                    <li><a href="https://www.rampagesoft.com/AboutUs.php">About the company</a></li>
                    <li><a href="https://www.rampagesoft.com/article.php">Company news</a></li>
                    <li><a href="https://afraapache.com/privacypolicy">Privacy</a></li>
                    <li><a href="https://afraapache.com/termsandconditions">Terms and conditions</a></li>
                  </ul>
                </div>

                <div className="footer__social footer__social-md social-border-top mt-30 pt-30 posi-footer">
                  {footerData.socialMedia.map((media) => (
                    <a key={media.name} href={media.url} target="_blank" rel="noopener noreferrer"><i className={media.icon}></i></a>
                  ))}
                </div>
              </div>

              {/* Services */}
              <div className="col-xl-3 col-sm-4 col-xs-3 col-12 col-lg-4 footer__pad">
                <div className="footer__widget-title">
                  <h4>{footerData.servicesTitle}</h4>
                </div>
                <div className="footer__link">
                  <ul>
                    {renderLinks(footerData.services)}
                  </ul>
                </div>
              </div>

              {/* Articles */}
              <div className="col-xl-3 col-sm-4 col-xs-3 col-12 col-lg-4 footer__pad">
                <div className="footer__widget-title">
                  <h4>{footerData.articlesTitle}</h4>
                </div>
                <div className="footer__link">
                  <ul>
                    {renderLinks(footerData.articles)}
                  </ul>
                </div>
              </div>

              {/* Contacts */}
              <div className="col-xl-3 col-sm-4 col-xs-3 col-12 col-lg-4 footer__pad">
                <div className="footer__widget-title">
                  <h4>{footerData.contactsTitle}</h4>
                </div>
                <div className="footer__link">
                  <ul>
                    {renderContacts(footerData.contacts)}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer__bottom-wrapper">
          <div className="container">
            <div className="footer__bottom">
              <div className="footer__copyright">

                <p>
                  COPYRIGHT ©<a href="https://www.rampagesoft.com/">RAMPAGESOFT</a> 2017-2024 ALL RIGHTS RESERVED | DESIGNED BY <a href="https://afraapache.com/">AFRA APACHE</a></p>

              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
