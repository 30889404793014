
///var/www/website2023/web-design.rampagesoft.com/my-app/src/package-website.js

import React from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import '../css/package-website.scss';

import packageBgImage from '../media/logo/pricing-bg.svg';

const PricingCardPrice = styled.div`
    background-image: url(${packageBgImage});
`;



const ZoomInBlur = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <div ref={ref} style={{
      opacity: inView ? 1 : 0,
      transform: inView ? 'scale(1)' : 'scale(1.1)',
      filter: inView ? 'blur(0px)' : 'blur(4px)',
      transition: 'all 1.0s ease-out'
    }}>
      {children}
    </div>
  );
};

const PackageWebsite = ({ content }) => {
  return (
    <section className='pad-tb-100 bg-subk'>
      <div className="container">
        <h2 className="head1-detallphoto">{content.heading1}</h2>
        <h2 className="head2-detallphoto">{content.heading2}</h2>
        <br/>
        <div className="row mb-minus-30">
          {content.packages.map((pkg, index) => (
            <div key={index} className="col-xl-4 col-md-12 col-lg-4 col-12">
              <ZoomInBlur>
                <div className={`price-text-${index === 0 ? 'red' : index === 1 ? 'blue' : 'gold'} pricing-card bd-3r overflow-hidden mb-30 wow fadeIn animated`} data-wow-delay=".5s" data-bg-color="#fff">
                  <img className='img-bottom20' src={pkg.image} alt={`Package ${pkg.price}`} />
                  <PricingCardPrice className="pricing-card-price mb-30 color-theme-primary price-bg price-text-head">
                    <h2>ราคา<span className='text-price'> {pkg.price}</span> <span>/บาท</span></h2>
                  </PricingCardPrice>
                  <div className="pricing-card-body mb-40">
                    <ul>
                      {pkg.features.map((feature, idx) => <li key={`${pkg.price}-${idx}`}>{feature}</li>)}
                    </ul>
                  </div>
                  <a href={pkg.contactLink} className="rs-btn pricing-card-button">
                    รายละเอียดเพิ่มเติม
                    <span>
                      <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.3536 4.40043C10.5488 4.20517 10.5488 3.88858 10.3536 3.69332L7.17157 0.511342C6.97631 0.31608 6.65973 0.31608 6.46447 0.511342C6.2692 0.706604 6.2692 1.02319 6.46447 1.21845L9.29289 4.04688L6.46447 6.8753C6.2692 7.07056 6.2692 7.38715 6.46447 7.58241C6.65973 7.77767 6.97631 7.77767 7.17157 7.58241L10.3536 4.40043ZM-4.35033e-08 4.54688L10 4.54688L10 3.54688L4.35033e-08 3.54688L-4.35033e-08 4.54688Z" fill="white"></path>
                      </svg>
                    </span>
                  </a>
                </div>
              </ZoomInBlur>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PackageWebsite;