import React from "react";
import logoram from "./media/logo/LOGO_RAMPAGESOFT_2023.png";
import logottt from "./media/logo/logo512.webp";

const Menu = () => {
  return (
    <section className=" bg-subk">
        <div className="row flex-xl-row">
          <div className="col-sm-6 col-md-6 col-xs-6 col-xl-6 col-12 col-lg-6 df-cen backttt">
            <div class="hovereffect">
              <img class="img-responsive" src={logottt} alt="" />
              <div class="overlay">
                <h2>
                  {" "}
                  Visit our blog, By{" "}
                  <a href="https://blog.ttt-website.com/">
                    blog.ttt-website.com
                  </a>{" "}
                  and start Web-Design.
                </h2>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-6 col-xs-6 col-xl-6 col-12 col-lg-6 df-cen bg-bklo">
            <div class="hovereffect">
              <img class="img-responsive" src={logoram} alt="" />
            </div>
          </div>
        </div>
    </section>
  );
};

export default Menu;
